import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Field, Formik, FieldArray } from 'formik'

import * as Yup from 'yup';
import Errors from '../../validations/Errors';
import { toast } from 'react-toastify';
import { listReservations, saveReservation, deleteReservation, selectedReservation, toggleReservationsSidebar } from '../../actions/reservationsActions';
import { userAuthPermissions } from '../../actions/usersActions';

import { _trans } from '../../actions/locoBOActions';

export default function FormComponent() {

    const { locosBO, loading: locosBOLoading, error: locosBOError } = useSelector(state => state.listLocosBO)

    const dispatch = useDispatch();

    const [id, setId] = useState('');

    const [selectAll, setSelectAll] = useState(false);

    const [allCheckboxes, setCheckboxes] = useState([]);


    const [date, setDate] = useState('');
    const [time, setTime] = useState('');
    const [party, setParty] = useState('');
    const [name, setName] = useState('');
    const [tel, setTel] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [statuts, setStatuts] = useState('');


    const [saveSuccess, setSaveSuccess] = useState(false);

    const reservationsToggleSidebar = useSelector(state => state.toggleReservationsSidebar);

    const reservationSelected = useSelector(state => state.selectedReservation);

    const reservationsSave = useSelector(state => state.reservationsSave);

    const [value, setValue] = useState(0); // integer state
    const { loading: loadingSave,
        success: successSave,
        error: errorSave } = reservationsSave;

    useEffect(() => {
        if (reservationSelected.reservation) {

            setId(reservationSelected.reservation.id);
            setDate(reservationSelected.reservation.date);
            setTime(reservationSelected.reservation.time);
            setParty(reservationSelected.reservation.party);
            setName(reservationSelected.reservation.name);
            setTel(reservationSelected.reservation.tel);
            setEmail(reservationSelected.reservation.email);
            setMessage(reservationSelected.reservation.message);
            setStatuts(reservationSelected.reservation.statuts);

        } else {
            setId('');
            setDate('');
            setTime('');
            setParty('');
            setName('');
            setTel('');
            setEmail('');
            setMessage('');
            setStatuts('');
        }

        if (saveSuccess) {
            setSaveSuccess(false);
            dispatch(listReservations());
            toast.success(_trans('Reservation Saved', locosBO));
        }

    }, [reservationSelected, saveSuccess]);

    const closeFormSidebar = () => {
        dispatch(selectedReservation(null));
        dispatch(toggleReservationsSidebar(false));
    }

    const handleSubmit = (values) => {
        dispatch(saveReservation(values, (response) => {
            dispatch(userAuthPermissions());
            setSaveSuccess(true);
        }));
    }

    const selectAllHandler = () => {
        let checkboxes = allCheckboxes;
        for (let i = 0; i < checkboxes.length; i++) {
            checkboxes[i] = !selectAll;
        }
        setCheckboxes(checkboxes);
        setSelectAll(!selectAll);
    }


    return <React.Fragment>
        {loadingSave && <div></div>}
        {errorSave && <div>{errorSave}</div>}
        <div className={reservationsToggleSidebar.toggle ? "contact-compose-sidebar show" : "contact-compose-sidebar"}>
            <div className="card quill-wrapper">
                <div className="card-content pt-0">
                    <div className="card-header display-flex pb-2">
                        <h3 className="card-title contact-title-label">{id === '' ? _trans('Create New Reservation', locosBO) : _trans('Update Reservation', locosBO)}</h3>
                        <div className="close close-icon" onClick={closeFormSidebar}>
                            <i className="material-icons">close</i>
                        </div>
                    </div>
                    <div className="divider"></div>

                    <Formik
                        initialValues={{
                            id: id ? id : '',
                            date: date ? date : '',
                            time: time ? time : '',
                            party: party ? party : '',
                            name: name ? name : '',
                            tel: tel ? tel : '',
                            email: email ? email : '',
                            message: message ? message : '',
                            statuts: statuts ? statuts : '',
                        }}
                        enableReinitialize={true}
                        onSubmit={async (values, { resetForm }) => {
                            closeFormSidebar();
                            values = { ...values };
                            setSelectAll(false);
                            handleSubmit(values);
                            // setReservationPermissions([]);
                            resetForm({ values: '' });
                        }}
                    >
                        {({ values, errors, touched, isSubmitting, setFieldValue }) => (
                            <Form className="edit-reservation-item mb-5 mt-5">
                                <div className="row">
                                    <div className="input-field col s12">
                                        <Field id="reservations_date" name="date" type="text" className={errors.date ? "error validate" : "validate"} />
                                        <label htmlFor="reservations_date" className={date !== '' ? 'active' : ''}>{_trans('Date', locosBO)}</label>
                                        {errors.date && touched.date ? (
                                            <small className="errorTxt2"><div id="cemail-error" className="error">{errors.date}</div></small>
                                        ) : null}
                                    </div>
                                    <div className="input-field col s12">
                                        <Field id="reservations_time" name="time" type="text" className={errors.time ? "error validate" : "validate"} />
                                        <label htmlFor="time" className={time !== '' ? 'active' : ''}>{_trans('Time', locosBO)}</label>
                                        {errors.time && touched.time ? (
                                            <small className="errorTxt2"><div id="cemail-error" className="error">{errors.time}</div></small>
                                        ) : null}
                                    </div>
                                    <div className="input-field col s12">
                                        <Field id="reservations_party" name="party" type="text" className={errors.party ? "error validate" : "validate"} />
                                        <label htmlFor="party" className={party !== '' ? 'active' : ''}>{_trans('party', locosBO)}</label>
                                        {errors.party && touched.party ? (
                                            <small className="errorTxt2"><div id="cemail-error" className="error">{errors.party}</div></small>
                                        ) : null}
                                    </div>
                                    <div className="input-field col s12">
                                        <Field id="reservations_name" name="name" type="text" className={errors.name ? "error validate" : "validate"} />
                                        <label htmlFor="reservations_name" className={name !== '' ? 'active' : ''}>{_trans('Name', locosBO)}</label>
                                        {errors.name && touched.name ? (
                                            <small className="errorTxt2"><div id="cemail-error" className="error">{errors.name}</div></small>
                                        ) : null}
                                    </div>
                                    <div className="input-field col s12">
                                        <Field id="reservations_tel" name="tel" type="text" className={errors.tel ? "error validate" : "validate"} />
                                        <label htmlFor="tel" className={tel !== '' ? 'active' : ''}>{_trans('Telephone', locosBO)}</label>
                                        {errors.tel && touched.tel ? (
                                            <small className="errorTxt2"><div id="cemail-error" className="error">{errors.tel}</div></small>
                                        ) : null}
                                    </div>
                                    <div className="input-field col s12">
                                        <Field id="reservations_email" name="email" type="text" className={errors.email ? "error validate" : "validate"} />
                                        <label htmlFor="email" className={email !== '' ? 'active' : ''}>{_trans('Email', locosBO)}</label>
                                        {errors.email && touched.email ? (
                                            <small className="errorTxt2"><div id="cemail-error" className="error">{errors.email}</div></small>
                                        ) : null}
                                    </div>
                                    <div className="input-field col s12">
                                        <Field id="reservations_message" name="message" type="text" className={errors.message ? "error validate" : "validate"} />
                                        <label htmlFor="reservations_message" className={message !== '' ? 'active' : ''}>{_trans('Message', locosBO)}</label>
                                        {errors.message && touched.message ? (
                                            <small className="errorTxt2"><div id="cemail-error" className="error">{errors.message}</div></small>
                                        ) : null}
                                    </div>
                                    <div className="input-field col s12">
                                        <Field id="reservations_statuts" name="statuts" type="text" className={errors.statuts ? "error validate" : "validate"} />
                                        <label htmlFor="reservations_statuts" className={statuts !== '' ? 'active' : ''}>{_trans('Statuts', locosBO)}</label>
                                        {errors.statuts && touched.statuts ? (
                                            <small className="errorTxt2"><div id="cemail-error" className="error">{errors.statuts}</div></small>
                                        ) : null}
                                    </div>

                                </div>

                                <div className="card-action pl-0 pr-0 right-align">
                                    <button type="submit" className="btn-small waves-effect waves-light add-reservation" disabled={isSubmitting}>
                                        {!id ? (
                                            <span>{_trans('Save', locosBO)}</span>
                                        ) : (
                                            <span>{_trans('Edit', locosBO)}</span>
                                        )}
                                    </button>
                                </div>

                            </Form>
                        )}

                    </Formik>

                </div>
            </div>
        </div>
    </React.Fragment>
}
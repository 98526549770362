import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { _trans } from '../../actions/locoBOActions';

export default function ReservationsCount(props) {

    const { locosBO, loading: locosBOLoading, error: locosBOError } = useSelector(state => state.listLocosBO)

    const reservationsList = useSelector(state => state.reservationsList);
    const { reservations,
        loading,
        error } = reservationsList;

    useEffect(() => {

    }, [reservations]);

    return (
        <React.Fragment>
            {loading ? <div></div> :
                error ? <div>{error}</div> :

                    <p className="m-0 text-muted">{reservations.length} {_trans('Reservations', locosBO)}</p>
            }
        </React.Fragment>
    );
}
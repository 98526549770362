import axios from 'axios';
import { headers } from '../config/ApiHeaders'
import { apiProxy } from '../config/Settings'

const { RESERVATIONS_LIST_REQUEST,
    RESERVATIONS_LIST_SUCCESS,
    RESERVATIONS_LIST_FAIL,
    RESERVATIONS_SAVE_REQUEST,
    RESERVATIONS_SAVE_SUCCESS,
    RESERVATIONS_SAVE_FAIL,
    RESERVATIONS_DELETE_REQUEST,
    RESERVATIONS_DELETE_SUCCESS,
    RESERVATIONS_DELETE_FAIL,
    SELECTED_RESERVATION,
    RESERVATIONS_SIDEBAR_IS_TOGGLED } = require("../constants/reservationsConstants");

const listReservations = () => async (dispatch) => {
    try {
        dispatch({ type: RESERVATIONS_LIST_REQUEST });
        const { data } = await axios({
            method: 'get',
            url: apiProxy + "/api/reservations",
            headers
        });
        dispatch({ type: RESERVATIONS_LIST_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: RESERVATIONS_LIST_FAIL, payload: error.message });
    }

}

const saveReservation = (reservation, callback) => async (dispatch) => {

    try {

        if (!reservation.id) {
            dispatch({ type: RESERVATIONS_SAVE_REQUEST, payload: { reservation } });
            const { data } = await axios({
                method: 'post',
                data: reservation,
                url: apiProxy + "/api/reservations",
                headers
            });
            callback(data);
            dispatch({ type: RESERVATIONS_SAVE_SUCCESS, payload: data });
        } else {
            dispatch({ type: RESERVATIONS_SAVE_REQUEST, payload: { reservation } });
            const { data } = await axios({
                method: 'patch',
                data: reservation,
                url: apiProxy + "/api/reservations/" + reservation.id,
                headers
            });
            callback(data);
            dispatch({ type: RESERVATIONS_SAVE_SUCCESS, payload: data });
        }

    } catch (error) {
        dispatch({ type: RESERVATIONS_SAVE_FAIL, payload: error.message });
    }

}

const deleteReservation = (reservation, callback) => async (dispatch) => {
    try {
        dispatch({ type: RESERVATIONS_DELETE_REQUEST });
        const { data } = await axios({
            method: 'delete',
            url: apiProxy + "/api/reservations/" + reservation.id,
            headers
        });
        callback(data);
        dispatch({ type: RESERVATIONS_DELETE_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: RESERVATIONS_DELETE_FAIL, payload: error.message });
    }

}

const selectedReservation = (reservation) => async (dispatch) => {
    dispatch({ type: SELECTED_RESERVATION, payload: reservation });
}

const toggleReservationsSidebar = (toggle) => async (dispatch) => {
    dispatch({ type: RESERVATIONS_SIDEBAR_IS_TOGGLED, payload: toggle });
}

export { listReservations, saveReservation, deleteReservation, selectedReservation, toggleReservationsSidebar }
const { RESERVATIONS_LIST_REQUEST,
    RESERVATIONS_LIST_SUCCESS,
    RESERVATIONS_LIST_FAIL,
    RESERVATIONS_SAVE_REQUEST,
    RESERVATIONS_SAVE_SUCCESS,
    RESERVATIONS_SAVE_FAIL,
    RESERVATIONS_DELETE_REQUEST,
    RESERVATIONS_DELETE_SUCCESS,
    RESERVATIONS_DELETE_FAIL,
    SELECTED_RESERVATION,
    RESERVATIONS_SIDEBAR_IS_TOGGLED } = require("../constants/reservationsConstants");

function reservationsListReducer(state = { loading: true, reservations: [] }, action) {

    switch (action.type) {
        case RESERVATIONS_LIST_REQUEST:
            return { loading: true };
        case RESERVATIONS_LIST_SUCCESS:
            return { loading: false, reservations: action.payload };
        case RESERVATIONS_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }

}

function reservationsSaveReducer(state = { reservations: [] }, action) {

    switch (action.type) {
        case RESERVATIONS_SAVE_REQUEST:
            return { loading: true };
        case RESERVATIONS_SAVE_SUCCESS:
            return { loading: false, success: true, reservations: action.payload };
        case RESERVATIONS_SAVE_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }

}

function reservationsDeleteReducer(state = { reservations: [] }, action) {

    switch (action.type) {
        case RESERVATIONS_DELETE_REQUEST:
            return { loading: true };
        case RESERVATIONS_DELETE_SUCCESS:
            return { loading: false, success: true, reservations: action.payload };
        case RESERVATIONS_DELETE_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }

}

function selectedReservationReducer(state = { reservation: null }, action) {

    switch (action.type) {
        case SELECTED_RESERVATION:
            return { reservation: action.payload };
        default:
            return state;
    }

}

function toggleReservationsSidebarReducer(state = { toggle: false }, action) {

    switch (action.type) {
        case RESERVATIONS_SIDEBAR_IS_TOGGLED:
            return { toggle: action.payload };
        default:
            return state;
    }

}


export { reservationsListReducer, reservationsSaveReducer, reservationsDeleteReducer, selectedReservationReducer, toggleReservationsSidebarReducer }
import React, { useEffect } from 'react'
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux';
import { locosBOList } from '../actions/locoBOActions';

// pages 
import Dashboard from '../components/Dashboard';
import Users from '../components/Users/index';
import Profile from '../components/Users/Profile/Profile';
import Permissions from '../components/Permissions/index';
import Roles from '../components/Roles/index';
import FilesManager from '../components/FilesManager/index';
import PostType from '../components/PostType/index';
import PostsList from '../components/Posts/PostsList/PostsList';
import PostsForm from '../components/Posts/PostsForm';
import PostTemplates from '../components/PostTemplates/index';
import PostTemplatesForm from '../components/PostTemplates/PostTemplatesForm';
import Forbidden from '../components/errors/Forbidden'
import NotFound from '../components/errors/notFound'
import Header from '../components/Layout/Header'
import SideNav from '../components/Layout/SideNav'
import Footer from '../components/Layout/Footer'
// Categories
import Categories from '../components/categories/Categories'
// Category Items
import CategoryItems from '../components/categoryItems/CategoryItems'
// Languages
import Languages from '../components/languages/Languages'
// Menus
import Menus from '../components/Menus/Menus'
//Menu items
import MenuItems from '../components/MenuItems/MenuItems'
// AuthorizedApps
import AuthorizedApps from '../components/authorizedApps/AuthorizedApps'
// Loco 
import Loco from '../components/LocoTranslate/Loco'
// Loco BO
import LocoBO from '../components/LocoBOTranslate/LocoBO'
// Loader 
import Loading from '../components/loader/Loading'
//Leads
import Leads from '../components/Leads/index';
//Contacts
import Contacts from '../components/Contacts/index';
//Reservations
import Reservations from '../components/Reservations/index';

export default function Routes() {

    const dispatch = useDispatch();

    const { loading, userAuthPermissions } = useSelector(state => state.userAuthPermissions);
    const { sideBarItems } = useSelector(state => state.sideBarItems);

    const { locosBO, loading: locosBOLoading, error: locosBOError } = useSelector(state => state.listLocosBO)

    useEffect(() => {
        dispatch(locosBOList());
    }, [])

    const hasPermissions = (path) => {
        const currentItem = sideBarItems?.find((item) => {
            return item.to === path
        })
        return userAuthPermissions?.find((item) => {
            return item?.key === currentItem?.key || item?.key === "ALL" && item.show
        })
    }

    /* routes */

    return (
        <React.Fragment>
            {console.log(locosBO)}
            <Header />
            <SideNav />
            <div id="main" >
                <Switch>
                    <Route path="/" exact={true} render={props => {
                        const path = "/";
                        if (loading) {
                            return <Loading {...props} />
                        }
                        if (hasPermissions(path)) {
                            return <Dashboard {...props} />
                        } else {
                            return <Forbidden {...props} />
                        }

                    }} />
                    {/* users  */}
                    <Route path="/users" exact={true} render={props => {
                        const path = "/users";
                        if (loading) {
                            return <Loading {...props} />
                        }
                        if (hasPermissions(path)) {
                            return <Users {...props} />
                        } else {
                            return <Forbidden {...props} />
                        }

                    }} />
                    {/* permissions  */}
                    <Route path="/permissions" exact={true} render={props => {
                        const path = "/permissions";
                        if (loading) {
                            return <Loading {...props} />
                        }
                        if (hasPermissions(path)) {
                            return <Permissions {...props} />
                        } else {
                            return <Forbidden {...props} />
                        }

                    }} />
                    {/* roles */}
                    <Route path="/roles" exact={true} render={props => {
                        if (loading) {
                            return <Loading {...props} />
                        }
                        const path = "/roles";
                        if (hasPermissions(path)) {
                            return <Roles {...props} />
                        } else {
                            return <Forbidden {...props} />
                        }

                    }} />
                    {/* profile */}
                    <Route path="/profile" exact={true} render={props => {
                        if (loading) {
                            return <Loading {...props} />
                        }
                        const path = "/profile";
                        if (hasPermissions(path)) {
                            return <Profile {...props} />
                        } else {
                            return <Forbidden {...props} />
                        }

                    }} />
                    {/* files  */}
                    <Route path="/files-manager/:filtre?" exact={true} render={props => {
                        if (loading) {
                            return <Loading {...props} />
                        }
                        const path = "/files-manager";

                        if (hasPermissions(path)) {
                            return <FilesManager {...props} />
                        } else {
                            return <Forbidden {...props} />
                        }

                    }} />
                    {/* posts  */}
                    <Route path="/post-type/:trush?" exact={true} render={props => {
                        if (loading) {
                            return <Loading {...props} />
                        }
                        const path = "/post-type";
                        if (hasPermissions(path)) {
                            return <PostType {...props} />
                        } else {
                            return <Forbidden {...props} />
                        }

                    }} />
                    <Route path="/posts/save/:id_type/:id_post?" exact={true} render={props => {
                        if (loading) {
                            return <Loading {...props} />
                        }
                        let path = "/posts";
                        if (props.match.params.id) {
                            path = "/posts/" + props.match.params.id_type;
                        }
                        if (hasPermissions(path)) {
                            return <PostsForm {...props} />
                        } else {
                            return <Forbidden {...props} />
                        }

                    }} />
                    <Route path="/posts/:trush?/:id" exact={true} render={props => {
                        if (loading) {
                            return <Loading {...props} />
                        }
                        let path = "/posts"
                        if (props.match.params.id) {
                            path = "/posts/" + props.match.params.id;
                        } else if (props.match.params.id && props.match.params.trush) {
                            path = "/posts/" + props.match.params.trush + "/" + props.match.params.id;
                        }
                        if (hasPermissions(path)) {
                            return <PostsList {...props} />
                        } else {
                            return <Forbidden {...props} />
                        }

                    }} />
                    {/* categories  */}
                    <Route path="/categories/:trush?" exact={true} render={props => {
                        if (loading) {
                            return <Loading {...props} />
                        }
                        const path = "/categories";
                        if (hasPermissions(path)) {
                            return <Categories {...props} locosBO={locosBO} />
                        } else {
                            return <Forbidden {...props} />
                        }

                    }} />
                    {/* Menu items  */}
                    <Route path="/category-items/:id?" exact={true} render={props => {
                        if (loading) {
                            return <Loading {...props} />
                        }
                        const path = "/category-items";
                        if (hasPermissions(path)) {
                            return <CategoryItems {...props} />
                        } else {
                            return <Forbidden {...props} />
                        }

                    }} />
                    {/* templates  */}
                    <Route path="/post-templates/:trush?" exact={true} render={props => {
                        if (loading) {
                            return <Loading {...props} />
                        }
                        const path = "/post-templates";
                        if (hasPermissions(path)) {
                            return <PostTemplates {...props} />
                        } else {
                            return <Forbidden {...props} />
                        }

                    }} />
                    <Route path="/post-templates-form" exact={true} render={props => {
                        if (loading) {
                            return <Loading {...props} />
                        }
                        const path = "/post-templates-form";
                        if (hasPermissions(path)) {
                            return <PostTemplatesForm {...props} />
                        } else {
                            return <Forbidden {...props} />
                        }

                    }} />
                    {/* languages */}
                    <Route path="/languages/:trush?" exact={true} render={props => {
                        if (loading) {
                            return <Loading {...props} />
                        }
                        const path = "/languages"
                        if (hasPermissions(path)) {
                            return <Languages {...props} />
                        } else {
                            return <Forbidden {...props} />
                        }

                    }} />
                    {/* Loco translate */}
                    <Route path="/loco-translate" exact={true} render={props => {
                        if (loading) {
                            return <Loading {...props} />
                        }
                        const path = "/loco-translate";
                        if (hasPermissions(path)) {
                            return <Loco {...props} />
                        } else {
                            return <Forbidden {...props} />
                        }

                    }} />
                    {/* Loco BO translate */}
                    <Route path="/locobo-translate" exact={true} render={props => {
                        if (loading) {
                            return <Loading {...props} />
                        }
                        const path = "/loco-translate";
                        if (hasPermissions(path)) {
                            return <LocoBO {...props} />
                        } else {
                            return <Forbidden {...props} />
                        }

                    }} />
                    {/* Menus */}
                    <Route path="/menus" exact={true} render={props => {
                        if (loading) {
                            return <Loading {...props} />
                        }
                        const path = "/menus";
                        if (hasPermissions(path)) {
                            return <Menus {...props} />
                        } else {
                            return <Forbidden {...props} />
                        }

                    }} />
                    {/* Menu items  */}
                    <Route path="/menu-items/:id?" exact={true} render={props => {
                        if (loading) {
                            return <Loading {...props} />
                        }
                        const path = "/menu-items";
                        if (hasPermissions(path)) {
                            return <MenuItems {...props} />
                        } else {
                            return <Forbidden {...props} />
                        }

                    }} />
                    {/* Authorized   */}
                    <Route path="/authorized-apps/:trush?" exact={true} render={props => {
                        if (loading) {
                            return <Loading {...props} />
                        }
                        const path = "/authorized-apps";
                        if (hasPermissions(path)) {
                            return <AuthorizedApps {...props} />
                        } else {
                            return <Forbidden {...props} />
                        }

                    }} />
                    {/* Leads  */}
                    <Route path="/leads" exact={true} render={props => {
                        const path = "/leads";
                        if (loading) {
                            return <Loading {...props} />
                        }
                        if (hasPermissions(path)) {
                            return <Leads {...props} />
                        } else {
                            return <Forbidden {...props} />
                        }

                    }} />
                    {/* Contacts  */}
                    <Route path="/contacts" exact={true} render={props => {
                        const path = "/contacts";
                        if (loading) {
                            return <Loading {...props} />
                        }
                        if (hasPermissions(path)) {
                            return <Contacts {...props} />
                        } else {
                            return <Forbidden {...props} />
                        }

                    }} />
                    {/* Reservations  */}
                    <Route path="/reservations" exact={true} render={props => {
                        const path = "/reservations";
                        if (loading) {
                            return <Loading {...props} />
                        }
                        if (hasPermissions(path)) {
                            return <Reservations {...props} />
                        } else {
                            return <Forbidden {...props} />
                        }

                    }} />
                    {/* 404 */}
                    <Route component={NotFound} />
                </Switch>
            </div>
            <Footer />

        </React.Fragment>

    )
}
